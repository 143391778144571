import PropTypes from 'prop-types';
import ArrowUpwardIcon from '@mui/icons-material/ArrowRightAltSharp'; // 导入向上箭头图标
// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Button, ButtonBase, SvgIcon } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// project imports
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';
import ThemeButton from 'ui-component/ThemeButton';

// assets
import { IconMenu2 } from '@tabler/icons-react';
// 自定义向右斜着向上弯的箭头
function CurvedArrowIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M3 15c0-.55.45-1 1-1h6c.55 0 1-.45 1-1V7.41l1.88 1.88a.996.996 0 101.41-1.41L10.71 4.29a1.003 1.003 0 00-1.42 0L6.71 6.88a.996.996 0 101.41 1.41L9 7.41V13H4c-.55 0-1 .45-1 1s.45 1 1 1h8c.55 0 1-.45 1-1V5.41l2.29 2.29a.996.996 0 101.41-1.41L12.71 2.29a.996.996 0 00-1.42 0L6.71 6.88a.996.996 0 101.41 1.41L9 7.41V13c0 .55-.45 1-1 1H4c-.55 0-1 .45-1 1z"
      />
    </SvgIcon>
  );
}
// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();
  const navigate = useNavigate(); // 获取导航函数
  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
          <LogoSection />
        </Box>
        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              ...theme.typography.menuButton,
              transition: 'all .2s ease-in-out',
              '&:hover': {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light
              }
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </Box>

      <Box sx={{ flexGrow: 1 }} />
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/')}
        sx={{ marginRight: '8px' }}
      >
        <CurvedArrowIcon sx={{ marginRight: '4px' }} /> {/* 左侧箭头图标 */}
        抱脸虫客户端下载
      </Button>

      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/boost')}
        sx={{ marginRight: '8px' }}
      >
        <CurvedArrowIcon sx={{ marginRight: '4px' }} /> {/* 左侧箭头图标 */}
        加速猫客户端下载
      </Button>
      <Box sx={{ flexGrow: 1 }} />

      <ThemeButton />
      <ProfileSection />
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;
